import { WIX_MINI_FILTER_EVENT_NAME } from '@va/constants';
import { useTranslate } from '@va/localization';
import { FilterInputComponentRefType } from '@va/types/filters';
import { SearchFilterV2 } from '@va/ui/components/inputs';
import { InputMessage, MenuList, MenuListItemType, Paragraph } from '@va/ui/design-system';
import { isWixMiniApp } from '@va/util/helpers';
import { DashboardAppWorker } from '@va/util/misc';
import { isNil } from 'lodash';
import { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { filterValidationKeys } from '../../constants';
import { useFiltersContext } from '../../filters-context';
import { useFilterValidation } from '../../useFilterValidation';
import { PickOperatorBtn } from '../PickOperatorBtn';
import { useOperator } from '../useOperator';
import { SingleSelectFilter, SingleSelectFilterProps } from './SingleSelectFilter';

export type SingleSelectFilterInputProps = {
  filter: SingleSelectFilter;
} & SingleSelectFilterProps;

export const SingleSelectFilterInput = forwardRef<FilterInputComponentRefType, SingleSelectFilterInputProps>(
  ({ filter, showSearchInput, useOptions, searchPlaceholder }, ref) => {
    const [filterValue, setFilterValue] = useState('');
    const [selectedValue, setSelectedValue] = useState<string | number | undefined>(filter.values[0]);

    const { data: options = [], error: asyncErr, isLoading } = useOptions();

    const { operator, setOperator } = useOperator(filter.operator);

    const { applySingleNewFilter, isFilterApplied, updateAppliedFilter } = useFiltersContext();
    const translate = useTranslate();

    const onSubmit = useCallback(() => {
      if (isFilterApplied(filter.id)) {
        updateAppliedFilter(filter.id, { values: [selectedValue], operator });
        return;
      }

      const clone = Object.assign({}, filter);
      clone.values = [selectedValue];
      clone.operator = operator;
      applySingleNewFilter(clone);
    }, [applySingleNewFilter, filter, isFilterApplied, operator, selectedValue, updateAppliedFilter]);

    const { handleSubmit, validate, error } = useFilterValidation({
      value: selectedValue,
      onSubmit,
      validationFunc: (val) => {
        if (isNil(val)) {
          return filterValidationKeys.selectAtLeastOneOption;
        }
      },
    });

    useImperativeHandle(ref, () => ({ submit: handleSubmit }));

    const filteredOptions = useMemo(() => {
      return options.filter((option) => option.label?.toLowerCase().includes(filterValue.toLowerCase()));
    }, [filterValue, options]);

    const menuItems = useMemo<MenuListItemType[]>(
      () =>
        filteredOptions.map((option) => {
          const isSelected = selectedValue === option.value;
          return {
            id: option.value.toString(),
            label: option.label,
            icon: option.icon,
            selected: isSelected,
            onClick: () => {
              if (isWixMiniApp()) {
                const filterEventName = WIX_MINI_FILTER_EVENT_NAME[filter.name];

                if (filterEventName) {
                  DashboardAppWorker.trackEvent({
                    category: 'Recording Main View',
                    action: 'onclick',
                    label: `Add/Edit Filter ${filterEventName} ${option.value}`,
                    value: 'Button',
                  });
                }
              }

              validate(option.value);
              setSelectedValue(option.value);
            },
          };
        }),
      [filter.name, filteredOptions, selectedValue, validate],
    );

    if (isLoading) {
      return (
        <div className='space-y-2'>
          <Skeleton className='rounded-12 h-8' />
          <Skeleton className='rounded-12 h-8' />
          <Skeleton className='rounded-12 h-8' />
          <Skeleton className='rounded-12 h-8' />
          <Skeleton className='rounded-12 h-8' />
        </div>
      );
    }

    return (
      <div className='space-y-2'>
        <PickOperatorBtn
          onOperatorChange={setOperator}
          operatorOptions={filter.operatorOptions}
          selectedOperator={operator}
        />
        {error && <InputMessage error={translate(error)} />}
        {asyncErr && <InputMessage error={translate('notifications.generalError')} />}
        {showSearchInput && (
          <SearchFilterV2
            onChange={(value) => {
              setFilterValue(value);
            }}
            placeholder={searchPlaceholder}
          />
        )}

        {filteredOptions.length === 0 && <Paragraph className='p-5'>{translate('select.noOptions')}</Paragraph>}
        {filteredOptions.length > 0 && <MenuList className='max-h-40' items={menuItems} />}
      </div>
    );
  },
);
