import { ReportBlockNodeItem } from '@va/types/report-block';
import { Button } from '@va/ui/design-system';
import { useTooltipContext } from '@va/ui/tooltips';
import { useCallback } from 'react';
import { ReportBlockNodesTooltip } from './ReportBlockNodesTooltip';

export const ReportBlockVisibleNode = ({ node }: { node: ReportBlockNodeItem }) => {
  const { context } = useTooltipContext();

  const closeTooltip = useCallback(() => context.onOpenChange(false), [context]);

  return (
    node.headerComponent ?? (
      <ReportBlockNodesTooltip
        content={node.menuComponent?.(closeTooltip)}
        title={node.label}
        hideCloseButton={node.hideCloseButton}
      >
        <div>
          <Button icon={() => node.icon} />
        </div>
      </ReportBlockNodesTooltip>
    )
  );
};
