import { ReportBlockNodeItem } from '@va/types/report-block';
import { Button } from '@va/ui/design-system';
import { TooltipContext, useTooltipContext } from '@va/ui/tooltips';
import { DropdownArrow } from '@va/util/components';
import { useWindowDimensions } from '@va/util/hooks';
import classNames from 'classnames';
import { useCallback } from 'react';
import { ReportBlockNodesTooltip } from './ReportBlockNodesTooltip';

export const ReportBlockActiveNode = ({ node }: { node: ReportBlockNodeItem }) => {
  const { context } = useTooltipContext();
  const closeTooltip = useCallback(() => context.onOpenChange(false), [context]);
  const isActive = node.useIsActive?.();
  const { isMediumDevice } = useWindowDimensions();

  if (!isActive || !isMediumDevice) return null;

  if (!node.defaultActiveNode && node.headerComponent) return node.headerComponent;

  return (
    <ReportBlockNodesTooltip
      content={node.menuComponent?.(closeTooltip)}
      title={node.label}
      hideCloseButton={node.hideCloseButton}
    >
      <div>
        <TooltipContext.Consumer>
          {({ context }) => (
            <Button
              icon={() => node.icon}
              className={classNames('sm:w-9 sm:h-9 sm:p-9px', {
                '!bg-green-haze': !context.open,
              })}
              bottomIcon={
                <DropdownArrow iconClassName={classNames('w-2.5 sm:w-1.5')} color='#FFFFFF80' open={context.open} />
              }
              bottomIconWrapperCss='sm:bottom-0.5'
            />
          )}
        </TooltipContext.Consumer>
      </div>
    </ReportBlockNodesTooltip>
  );
};
