import { TEST_IDS } from '@va/constants';
import { useTranslate } from '@va/localization';
import { Button, fontWeights, Paragraph, paragraphSizes } from '@va/ui/design-system';
import { TooltipWrapper, useTooltipContext } from '@va/ui/tooltips';
import { PropsWithChildren, ReactNode } from 'react';

type ReportBlockNodesTooltipProps = {
  title?: string;
  hideCloseButton?: boolean;
  content: ReactNode;
};

export const ReportBlockNodesTooltip = ({
  children,
  title,
  hideCloseButton,
  content,
}: PropsWithChildren<ReportBlockNodesTooltipProps>) => {
  return (
    <TooltipWrapper
      useDefaultStyle={false}
      placement='bottom'
      trigger='click'
      arrow={false}
      interactive
      zIndex={'var(--zIndex-filters-dropdown)'}
      tooltipClassNames='flex flex-col bg-white rounded-15 p-1.5 w-screen max-w-sm shadow-xl sm:max-h-[45vh] max-h-[450px] overflow-hidden'
      content={
        <>
          {title && (
            <div className='p-9px pl-4'>
              <Paragraph size={paragraphSizes.tiny} weight={fontWeights.medium}>
                {title}
              </Paragraph>
            </div>
          )}
          <div className='overflow-hidden flex flex-col'>{content}</div>
          {!hideCloseButton && <CloseButton />}
        </>
      }
    >
      {children}
    </TooltipWrapper>
  );
};

const CloseButton = () => {
  const translate = useTranslate();
  const { context } = useTooltipContext();
  return (
    <Button
      data-testid={TEST_IDS.generic.buttons.close}
      onClick={() => context.onOpenChange(false)}
      color='tertiary'
      className='w-full mt-[3px] shrink-0 py-9px'
      textClasses='text-12 leading-18'
      text={translate('button.close')}
      size='small'
    />
  );
};
