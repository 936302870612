import { apiGateway } from '@va/api-client';
import { getInstanceId } from '@va/dashboard/selectors/app';
import { getConfig } from '@va/standalone/shared/api-client';
import { toQueryString } from '@va/util/helpers';
import { useAsyncFunction, useFetchData } from '@va/util/hooks';
import { useSelector } from 'react-redux';

export enum ExportFormat {
  xlsx = 'xlsx',
  csv = 'csv',
}

type ExportData<T> = {
  exportType: string;
  websiteUrl: string;
  from: number;
  until: number;
  locale: string;
  format: ExportFormat;
  payload: T;
  timezone: string;
  emailTo?: string;
};

export const requestExport = <T>(websiteId: string, data: ExportData<T>) => {
  return apiGateway.post(`/websites/${websiteId}/export`, data, getConfig());
};

export const useRequestExport = <T>() => {
  return useAsyncFunction(requestExport<T>, { throwErrorBack: true });
};

export const cancelJob = (websiteId: string, jobId: string) => {
  return apiGateway.post(`/websites/${websiteId}/export/cancel/${jobId}`, {}, getConfig());
};

export const useCancelJob = () => {
  return useAsyncFunction<typeof cancelJob>(cancelJob, { throwErrorBack: true });
};

export const listExportJobs = (websiteId: string, query: { length: number; start: number }) => {
  return apiGateway.get(`/websites/${websiteId}/export/jobs?${toQueryString(query)}`, getConfig());
};

export enum ExportJobStatus {
  failed = 'failed',
  inProgress = 'in_progress',
  completed = 'completed',
  pending = 'pending',
  cancelled = 'cancelled',
}

export type ExportJobItem = {
  id: string;
  createdAt: number;
  completedAt: number | null;
  fileId: string | null;
  format: ExportFormat;
  from: number;
  until: number;
  status: ExportJobStatus;
  websiteId: string;
  websiteUrl: string;
  exportType: string;
  progress: number;
  fileSize: number | null;
};

export const useListExportJobs = (query: { length: number; start: number }) => {
  const websiteId = useSelector(getInstanceId);

  return useFetchData<{
    jobs: ExportJobItem[];
    totalJobs: number;
  }>([`list-export-jobs`, websiteId, query], {}, undefined, () => listExportJobs(websiteId, query));
};
