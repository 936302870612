import { WIX_MINI_FILTER_EVENT_NAME } from '@va/constants';
import { Filter } from '@va/types/filters';
import { isWixMiniApp } from '@va/util/helpers';
import { useSubmitOnEnter } from '@va/util/hooks';
import { DashboardAppWorker } from '@va/util/misc';
import { useCallback, useMemo, useRef } from 'react';
import { useFiltersContext } from '../filters-context';
import { applyFilterValues } from '../table-filters-helpers';
import { FilterActionButtons } from './FilterActionButtons';
import { SubFilterTabHeader } from './SubFilterTabHeader';

export const IndividualFilter = ({
  filter,
  onBackClick,
  onClose,
}: {
  filter: Filter;
  onBackClick: () => void;
  onClose: () => void;
}) => {
  const { expandFilters, isFilterApplied } = useFiltersContext();
  const inputRef = useRef<{ submit: () => void }>(null);

  const Input = filter.input;

  const appliedFilter = useMemo(() => isFilterApplied(filter.id), [filter.id, isFilterApplied]);
  const updatedFilter = useMemo(() => {
    return appliedFilter ? applyFilterValues(filter, appliedFilter) : filter;
  }, [appliedFilter, filter]);

  const handleSubmit = useCallback(() => {
    if (isWixMiniApp()) {
      const filterEventName = WIX_MINI_FILTER_EVENT_NAME[filter.name];

      if (filterEventName) {
        DashboardAppWorker.trackEvent({
          category: 'Recording Main View',
          action: 'onclick',
          label: `Add/Edit Filter ${filterEventName} Submit`,
          value: 'Button',
        });
      }
    }

    try {
      inputRef.current?.submit();
      expandFilters();
      onClose();
      // eslint-disable-next-line no-empty
    } catch {}
  }, [filter.name, expandFilters, onClose]);

  useSubmitOnEnter(handleSubmit);

  return (
    <div className='overflow-hidden flex flex-col'>
      <SubFilterTabHeader title={filter.label} onBackClick={onBackClick} />
      <Input ref={inputRef} filter={updatedFilter} {...filter?.inputProps} />
      <FilterActionButtons onClose={onClose} onSubmit={handleSubmit} />
    </div>
  );
};
