import { TEST_IDS } from '@va/constants';
import { DateComparisonPeriod, PreviousToggle, useIsPrevPeriodAvailable } from '@va/dashboard/ui/components';
import { CalendarIcon, CompareIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { useReportBlockCtx } from '@va/ui/components/report-block';
import { MenuList, MenuListItemType, ToggleSwitch } from '@va/ui/design-system';
import { isNil } from 'lodash';
import { memo, useEffect, useMemo } from 'react';

export const ReportBlockComparison = memo(() => {
  const { showPrevious, setShowPrevious, activeComparisonOption, setActiveComparisonOption } = useReportBlockCtx();

  return (
    <PreviousToggle
      selected={showPrevious}
      onToggle={() => setShowPrevious(!showPrevious)}
      activeComparisonOption={activeComparisonOption}
      setActiveComparisonOption={setActiveComparisonOption}
    />
  );
});

export const ReportBlockComparisonTooltip = () => {
  const translate = useTranslate();
  const { showPrevious, setShowPrevious, activeComparisonOption, setActiveComparisonOption } = useReportBlockCtx();
  const isYoyComparisonEnabled = useMemo(() => !isNil(setActiveComparisonOption), [setActiveComparisonOption]);
  const isYoyComparisonSelected = useMemo(
    () => activeComparisonOption === DateComparisonPeriod.YOY,
    [activeComparisonOption],
  );

  const isPrevPeriodAvailable = useIsPrevPeriodAvailable();

  useEffect(() => {
    if (!isPrevPeriodAvailable && showPrevious) {
      setShowPrevious(false);
    }
  }, [isPrevPeriodAvailable, setShowPrevious, showPrevious]);

  const menuItems = useMemo<MenuListItemType[]>(
    () => [
      {
        id: DateComparisonPeriod.PREVIOUS,
        'data-testid': TEST_IDS.helpers.createListItemId(DateComparisonPeriod.PREVIOUS),
        onClick: () => {
          setActiveComparisonOption?.(DateComparisonPeriod.PREVIOUS);
          setShowPrevious(isYoyComparisonSelected || !showPrevious);
        },
        icon: <CompareIcon />,
        label: translate('label.compareToPreviousPeriod'),
        endAdornment: (
          <ToggleSwitch checked={!isYoyComparisonSelected && showPrevious} onChange={setShowPrevious} size='small' />
        ),
      },
      ...(isYoyComparisonEnabled
        ? [
            {
              id: DateComparisonPeriod.YOY,
              'data-testid': TEST_IDS.helpers.createListItemId(DateComparisonPeriod.YOY),
              onClick: () => {
                setActiveComparisonOption?.(DateComparisonPeriod.YOY);
                setShowPrevious(!isYoyComparisonSelected || !showPrevious);
              },
              icon: <CalendarIcon />,
              label: translate('label.compareToYearOverYearPeriod'),
              endAdornment: (
                <ToggleSwitch
                  checked={isYoyComparisonSelected && showPrevious}
                  onChange={setShowPrevious}
                  size='small'
                />
              ),
            },
          ]
        : []),
    ],
    [
      isYoyComparisonEnabled,
      isYoyComparisonSelected,
      setActiveComparisonOption,
      setShowPrevious,
      showPrevious,
      translate,
    ],
  );

  return (
    <div data-testid={TEST_IDS.generic.previousPeriodComparison.dropdownOptionsContainer} className='flex flex-col'>
      <MenuList items={menuItems} />
    </div>
  );
};
