import { TableListIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Button } from '@va/ui/design-system';
import classNames from 'classnames';

export const SaveTemplateBtnComponent = ({
  onClick,
  isAddFilterBtnVisible,
  disabled,
  tooltip,
  className,
}: {
  onClick: () => void;
  isAddFilterBtnVisible: boolean;
  disabled?: boolean;
  tooltip?: string;
  className?: string;
}) => {
  const translate = useTranslate();

  return (
    <Button
      color='quinary'
      text={translate('button.saveFiltersAsTemplate')}
      icon={(_, color) => <TableListIcon color={color} className='shrink-0' />}
      className={classNames(
        'h-42px flex-1 md:min-w-min sm:h-9 sm:py-9px sm:px-3 sm:divide-x-[7.5px] sm:gap-0',
        {
          'rounded-none': isAddFilterBtnVisible,
          'rounded-b-none md:rounded-r-none md:rounded-bl-12': !isAddFilterBtnVisible,
        },
        className,
      )}
      textClasses='text-12 sm:leading-18'
      onClick={onClick}
      disabled={disabled}
      tooltip={tooltip}
    />
  );
};
