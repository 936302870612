import { ChartTile } from '@va/types/charts';
import {
  BaseTimeseriesDataset,
  BaseTimeseriesItems,
  BaseTimeseriesResponse,
  BaseTimeseriesTotal,
  RawTimeseriesDataset,
  RawTimeseriesResponsePayload,
  TimeInterval,
} from '@va/types/time-series';
import {
  addTransparencyToHexColor,
  getLocalizedDate,
  getPercentageChange,
  getTimestampFromIso,
} from '@va/util/helpers';
import { ChartDataset, ChartTypeRegistry, ControllerDatasetOptions } from 'chart.js';
import { isNil, sum } from 'lodash';
import moment from 'moment';

function buildChartTiles({
  currentDatasets,
  currentTotal,
  previousTotal,
  getHeadingValueFunc,
  getValueDetailsFunc,
  locale,
  hiddenDatasets,
  renderContextMenu,
  translate,
}: {
  currentDatasets: BaseTimeseriesItems<string>;
  currentTotal: BaseTimeseriesTotal<string>;
  previousTotal: BaseTimeseriesTotal<string>;
  locale: string;
  hiddenDatasets: string[];
  translate: (key: string, options?: Record<string, unknown>) => any;
  getHeadingValueFunc?: (total: number, locale: string, dataset: BaseTimeseriesDataset<number>) => number;
  getValueDetailsFunc?: (dataset: BaseTimeseriesDataset, total: number) => any;
  renderContextMenu?: (tileKey: string) => React.ReactNode;
}): ChartTile[] {
  return Object.entries(currentDatasets).map(([key, dataset]) => {
    const totalCurrent = currentTotal[key] ?? 0;
    const totalPrevious = previousTotal[key] ?? 0;

    return {
      id: key,
      colorHEX: dataset.color,
      headingValue: getHeadingValueFunc ? getHeadingValueFunc(totalCurrent, locale, dataset) : totalCurrent,
      label: translate(dataset.label, dataset.labelExtras),
      selected: !hiddenDatasets.includes(key),
      percentageChange: !isNil(previousTotal) ? getPercentageChange(totalPrevious, totalCurrent) : undefined,
      valueDetails: getValueDetailsFunc?.(dataset, totalCurrent),
      renderContextMenu: renderContextMenu ? () => renderContextMenu(key) : undefined,
    };
  });
}

function buildChartDatasets<T extends keyof ChartTypeRegistry>({
  currentDatasets,
  previousDatasets,
  hiddenDatasets,
  isPreviousEnabled,
  getExtraConfig,
  getDatasetLabel,
}: {
  currentDatasets: BaseTimeseriesItems<string>;
  previousDatasets: BaseTimeseriesItems<string>;
  hiddenDatasets: string[];
  isPreviousEnabled: boolean;
  getExtraConfig?: (dataset: BaseTimeseriesDataset<any>) => Partial<ControllerDatasetOptions>;
  getDatasetLabel?: (dataset: BaseTimeseriesDataset<any>) => string;
}): ChartDataset<T>[] {
  const datasets: ChartDataset<T>[] = [];

  Object.entries(currentDatasets).forEach(([_, dataset]) => {
    datasets.push(
      buildChartDataset({
        dataset,
        hiddenDatasets: hiddenDatasets,
        isCurrent: true,
        isPreviousEnabled,
        getExtraConfig,
        getDatasetLabel,
      }),
    );
  });

  Object.entries(previousDatasets).forEach(([_, dataset]) => {
    datasets.push(
      buildChartDataset({
        dataset,
        hiddenDatasets: hiddenDatasets,
        isCurrent: false,
        isPreviousEnabled,
        getExtraConfig,
        getDatasetLabel,
      }),
    );
  });

  return datasets;
}

function buildChartDataset<T extends keyof ChartTypeRegistry>({
  dataset,
  hiddenDatasets,
  isCurrent,
  isPreviousEnabled,
  getExtraConfig,
  getDatasetLabel,
  getDatasetColor,
}: {
  dataset: BaseTimeseriesDataset<any>;
  hiddenDatasets: string[];
  isCurrent: boolean;
  isPreviousEnabled: boolean;
  getExtraConfig?: (dataset: BaseTimeseriesDataset<any>) => Partial<ControllerDatasetOptions>;
  getDatasetLabel?: (dataset: BaseTimeseriesDataset<any>) => string;
  getDatasetColor?: (dataset: BaseTimeseriesDataset<any>) => string;
}): ChartDataset<T> {
  const extraCfg = getExtraConfig ? getExtraConfig(dataset) : {};

  const color = getDatasetColor ? getDatasetColor(dataset) : dataset.color;

  // @ts-ignore
  return {
    data: dataset.data,
    label: getDatasetLabel ? getDatasetLabel(dataset) : dataset.label,
    borderColor: isCurrent ? color : addTransparencyToHexColor(color, 0.3),
    backgroundColor: isCurrent ? color : addTransparencyToHexColor(color, 0.3),
    isCurrent: isCurrent,
    linkTo: dataset.linkTo,
    onLinkClick: dataset.onLinkClick,
    linkLabel: dataset.linkLabel,
    hidden: isCurrent
      ? hiddenDatasets.includes(dataset.key)
      : !isPreviousEnabled || hiddenDatasets.includes(dataset.key),
    ...extraCfg,
  };
}

function buildBaseTimeseriesResponse(
  rawResponses: RawTimeseriesResponsePayload[],
  options: {
    extractDatasetKey: (dataset: RawTimeseriesDataset) => string;
    getDatasetColor?: (dataset: RawTimeseriesDataset) => string;
    getDatasetLabel: (dataset: RawTimeseriesDataset) => string;
    getLabelExtras?: (dataset: RawTimeseriesDataset) => Record<string, string>;
    getLinkToValue?: (key: string) => string | undefined;
    getLinkClickHandler?: (key: string) => () => void;
    getLinkLabel?: (key: string) => string;
    unit: string;
    until: string;
  },
): BaseTimeseriesResponse<string> {
  const total: BaseTimeseriesTotal<string> = {};
  const items: BaseTimeseriesItems<string> = {};

  if (rawResponses.length === 0) {
    return {
      items: {},
      labels: [],
      total: {},
      timeIntervals: [],
      chartLabels: [],
      tabularData: [],
    };
  }

  const {
    extractDatasetKey,
    getDatasetColor,
    getDatasetLabel,
    unit,
    until: intervalEnd,
    getLinkToValue,
    getLabelExtras,
    getLinkClickHandler,
    getLinkLabel,
  } = options;

  const rawLabels = rawResponses[0].labels;
  const chartLabels: string[][] = [];
  const timeIntervals: TimeInterval[] = [];
  const tabularData: Record<string, any>[] = [];

  rawResponses.forEach((res) => {
    res.datasets.forEach((dataset) => {
      const key = extractDatasetKey(dataset);

      let totalVal = 0;

      if (dataset.total) {
        totalVal = dataset.total;
      } else if (dataset.total_average) {
        totalVal = dataset.total_average;
      } else if (dataset.totalAverage) {
        totalVal = dataset.totalAverage;
      } else {
        totalVal = sum(dataset.data);
      }

      total[key] = totalVal;
      items[key] = {
        data: dataset.data,
        key: key,
        color: getDatasetColor ? getDatasetColor(dataset) : '',
        label: getDatasetLabel(dataset),
        labelExtras: getLabelExtras ? getLabelExtras(dataset) : undefined,
        linkTo: getLinkToValue ? getLinkToValue(key) : undefined,
        onLinkClick: getLinkClickHandler?.(key),
        linkLabel: getLinkLabel?.(key),
      };
    });
  });

  rawLabels.forEach((label, index) => {
    const from = mapChartLabel(label);
    let until: string;
    if (unit === 'day') {
      until = from;
    } else if (index === rawLabels.length - 1) {
      until = mapChartLabel(intervalEnd);
    } else {
      until = mapChartLabel(
        moment(rawLabels[index + 1])
          .subtract(1, 'day')
          .toISOString(true),
      );
    }

    timeIntervals[index] = { from: from, until: until };

    if (unit === 'day') {
      chartLabels[index] = [from];
    } else {
      chartLabels[index] = [from, `- ${until}`];
    }

    const tabularItem: Record<string, any> = {};
    Object.keys(items).forEach((key) => {
      tabularItem[key] = items[key].data[index];
    });
    if (unit === 'day') {
      tabularItem['date'] = from;
    } else {
      tabularItem['date'] = `${from}-${until}`;
    }
    tabularData[index] = tabularItem;
  });

  return {
    items: items,
    total: total,
    chartLabels: chartLabels,
    labels: [],
    timeIntervals: timeIntervals,
    tabularData: tabularData,
  };
}

export function mapChartLabel(label: string) {
  return moment(label).tz(window.timezone).format('L');
}

export function formatLineDiagramDateLabels(dateArray: string[][] | string[], locale: string) {
  return dateArray.map((dateEntry) => {
    if (Array.isArray(dateEntry)) {
      if (dateEntry.length === 1 || dateEntry[0] === dateEntry[1]) {
        const formattedDate = getLocalizedDate(getTimestampFromIso(dateEntry[0]), locale);
        return [formattedDate];
      } else {
        const formattedFrom = getLocalizedDate(getTimestampFromIso(dateEntry[0]), locale);
        const formattedUntil = getLocalizedDate(getTimestampFromIso(dateEntry[1]), locale);
        return [formattedFrom, `- ${formattedUntil}`];
      }
    }
    return getLocalizedDate(getTimestampFromIso(dateEntry), locale);
  });
}

export const chartHelpers = {
  buildChartDataset,
  buildChartDatasets,
  buildChartTiles,
  buildBaseTimeseriesResponse,
  mapChartLabel,
  formatLineDiagramDateLabels,
};
