import { UserRole } from '@va/types/website';
import Config from 'Config';
import flagsmith from 'flagsmith';
import { is3asApp, isWixApp, isWixMiniApp } from '../dashboard';

export enum FlagsmithFeatureFlags {
  OutgoingTraffic = 'outgoingTraffic',
  ABEs = 'abes',
  WebsiteErrors = 'websiteErrors',
  ManageApiKeys = 'manageApiKeys',
  TranslationKeys = 'translationKeys',
  Develop = 'develop',
}

export function isEcomModuleEnabled(userRole: UserRole) {
  if (isWixApp()) return false;
  return userRole !== UserRole.DASHBOARD;
}

export function isCommunityButtonEnabled() {
  if (is3asApp()) return false;

  return true;
}

export function isGAImportEnabled() {
  return true;
}

export function isAbesFeatureEnabled() {
  return flagsmith.hasFeature(FlagsmithFeatureFlags.ABEs);
}

export function isWebsiteErrorsEnabled() {
  return flagsmith.hasFeature(FlagsmithFeatureFlags.WebsiteErrors);
}

export function isDevelopFeatureEnabled() {
  return flagsmith.hasFeature(FlagsmithFeatureFlags.Develop);
}

export function isTranslationKeysAccessEnabled() {
  return flagsmith.hasFeature(FlagsmithFeatureFlags.TranslationKeys);
}

export function initializeFlagsmith() {
  try {
    flagsmith.init({
      environmentID: Config.flagSmithEnvironmentId,
      api: 'https://flags.hostedby.net/api/v1/',
      preventFetch: true,
      cacheFlags: true,
      onChange: (previousFlags, params, loadingState) => {},
      defaultFlags: {
        abes: { enabled: false },
        websiteErrors: { enabled: false },
        translationKeys: { enabled: false },
        outgoingTraffic: { enabled: false },
        develop: { enabled: false },
      },
    });
  } catch (e) {
    console.log('Flagsmith error:', e);
  }
}

export function isOutgoingTrafficFeatureEnabled() {
  return flagsmith.hasFeature(FlagsmithFeatureFlags.OutgoingTraffic);
}

export function isManageApiKeysFeatureEnabled() {
  return flagsmith.hasFeature(FlagsmithFeatureFlags.ManageApiKeys);
}
