import { AddSegmentsButton, AppliedSegmentsList, useSegmentsContext } from '@va/dashboard/shared/feature-segments';
import {
  ReportBlockComparison,
  ReportBlockComparisonTooltip,
  useIsPrevPeriodAvailable,
} from '@va/dashboard/ui/components';
import {
  CompareIcon,
  ExportIconV2,
  FilterIconV2,
  LocationIcon,
  NavSubscriptionSettings,
  NavTrafficStructure,
  SegmentIconV2,
  SquareBox,
} from '@va/icons';
import { useTranslate } from '@va/localization';
import { AddFilterButton, AvailableFiltersDropdownContent, useFiltersContext } from '@va/shared/feature-filters';
import { ReportBlockNodeItem } from '@va/types/report-block';
import { ReportBlockNodeIds, useReportBlockCtx } from '@va/ui/components/report-block';
import { useCallback, useMemo } from 'react';

type ReportBlockNodeBuilderOptions = Partial<Omit<ReportBlockNodeItem, 'id'>>;

export const useReportBlockNodeBuilder = () => {
  const translate = useTranslate();
  const isPreviousPeriodAvailable = useIsPrevPeriodAvailable();

  const defaultReportBlockNodes = useMemo<Record<ReportBlockNodeIds, ReportBlockNodeItem>>(
    () => ({
      [ReportBlockNodeIds.FILTER_BUTTON]: {
        id: ReportBlockNodeIds.FILTER_BUTTON,
        icon: <FilterIconV2 />,
        label: translate('tooltip.label.applyFilters'),
        headerComponent: <AddFilterButton />,
        hideCloseButton: true,
        menuComponent: (closeMenu) => <AvailableFiltersDropdownContent closeDropdown={closeMenu} />,
        defaultActiveNode: false,
        useIsActive: () => {
          const { appliedFilters } = useFiltersContext();
          return !!appliedFilters.length;
        },
      },
      [ReportBlockNodeIds.COMPARE_TO]: {
        id: ReportBlockNodeIds.COMPARE_TO,
        icon: <CompareIcon />,
        label: translate('button.compareTo'),
        headerComponent: <ReportBlockComparison />,
        menuComponent: () => <ReportBlockComparisonTooltip />,
        disabled: !isPreviousPeriodAvailable,
        tooltip: !isPreviousPeriodAvailable ? translate('tooltip.previousToggle.prevPeriodNotAvailable') : undefined,
        tooltipProps: { trigger: 'hover' },
        useIsActive: () => {
          const { showPrevious } = useReportBlockCtx();
          return showPrevious;
        },
      },
      [ReportBlockNodeIds.DOWNLOAD_BUTTON]: {
        id: ReportBlockNodeIds.DOWNLOAD_BUTTON,
        icon: <ExportIconV2 />,
        label: translate('button.download'),
        hideCloseButton: true,
      },
      [ReportBlockNodeIds.SEGMENTS_BUTTON]: {
        id: ReportBlockNodeIds.SEGMENTS_BUTTON,
        icon: <SegmentIconV2 />,
        label: translate('reportBlockNode.segments'),
        headerComponent: <AddSegmentsButton />,
        menuComponent: () => <AppliedSegmentsList />,
        defaultActiveNode: false,
        useIsActive: () => {
          const { selectedSegments } = useSegmentsContext();
          return !!selectedSegments.length;
        },
      },
      [ReportBlockNodeIds.DISPLAY_VISITOR_PINS_SWITCH]: {
        id: ReportBlockNodeIds.DISPLAY_VISITOR_PINS_SWITCH,
        icon: <LocationIcon />,
        label: translate('card.liveVisitors.visitorPins'),
      },
      [ReportBlockNodeIds.COMMISSION_PAYOUTS]: {
        id: ReportBlockNodeIds.COMMISSION_PAYOUTS,
        icon: <NavSubscriptionSettings className='h-18px w-18px' color='#333' />,
        label: translate('commission.payouts.totalCommission.label'),
      },
      [ReportBlockNodeIds.PRODUCT_SELECTOR]: {
        id: ReportBlockNodeIds.PRODUCT_SELECTOR,
        icon: <SquareBox className='h-18px w-18px' />,
        label: translate('all.ecom.labels.products'),
        hideCloseButton: true,
      },
      [ReportBlockNodeIds.METRIC_OPTIONS]: {
        id: ReportBlockNodeIds.METRIC_OPTIONS,
        icon: <NavTrafficStructure className='h-18px w-18px' color='#333' />,
        label: translate('all.labels.metricOptions'),
      },
    }),
    [isPreviousPeriodAvailable, translate],
  );

  const builder = useCallback(
    (id: ReportBlockNodeIds, options?: ReportBlockNodeBuilderOptions): ReportBlockNodeItem | undefined => {
      return { defaultActiveNode: true, ...defaultReportBlockNodes[id], ...options };
    },
    [defaultReportBlockNodes],
  );

  return builder;
};
