import { EVENTS } from '@va/constants';
import { useTranslate } from '@va/localization';
import { FilterCondition, FilterConditionsObject } from '@va/types/filter-templates';
import { Filter, FilterOperators, isFilter, isNestedFiltersPage, NestedFiltersPage } from '@va/types/filters';
import { buildApiConditions, createFiltersFromConditions, SessionStorage, storeFilters } from '@va/util/helpers';
import {
  EventBus,
  StorageKeysManager,
  useCustomizationContext,
  useEventsContext,
  usePermissionsProvider,
} from '@va/util/misc';
import { isNil } from 'lodash';
import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useGlobalFiltersConfig } from './ctx';

export type FilterOptions = Array<NestedFiltersPage | Filter>;

export type FilterContextType = {
  reportBlockId?: string;
  allFilterOptions: FilterOptions;
  appliedFilters: Filter[];
  applySingleNewFilter: (newFilter: Filter) => void;
  applyMultipleFilters: (newFilters: Filter[], deletePrevious?: boolean) => void;
  deleteAppliedFilter: (filterId: string) => void;
  deleteAllAppliedFilters: () => void;
  toggleAppliedFiltersVisibility: () => void;
  isExpanded: boolean;
  appliedFilterValues: Record<string, unknown>;
  getAppliedFilterValues: (removeEmptyValues?: boolean) => Record<string, unknown>;
  isFilterApplied: (filterId: string) => Filter | undefined;
  shouldHighlightFilter: (filter: Filter | NestedFiltersPage) => boolean;
  hasAvailableFiltersLeft: boolean;
  updateAppliedFilter: (
    filterId: string,
    data: {
      values?: any[];
      operator?: FilterOperators;
    },
  ) => void;
  applyConditions: (conditions: FilterConditionsObject) => void;
  templatesEnabled: boolean;
  createApiConditions: () => FilterCondition[];
  expandFilters: () => void;
  isDropdownOpen: boolean;
  setIsDropdownOpen: Dispatch<SetStateAction<boolean>>;
  preFilterChange?: (ctx: FilterContextType) => void;
};

const FiltersContext = createContext<FilterContextType>({ appliedFilters: [] as Filter[] } as unknown as FilterContextType);

const FiltersContextProvider: React.FC<
  PropsWithChildren<{
    allFilterOptions: FilterOptions;
    initialAppliedFilters?: Filter[] | null;
    reportBlockId?: string;
    onFilterChange?: (appliedFilter: Filter[]) => void;
    onFilterRemove?: (filter: Filter | 'all') => void;
    preFilterChange?: (ctx: FilterContextType) => void;
  }>
> = ({
  children,
  allFilterOptions,
  initialAppliedFilters,
  onFilterChange,
  reportBlockId,
  preFilterChange,
  onFilterRemove,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { getCustomValue } = useCustomizationContext();
  const { reportErrorFunc, websiteId } = useGlobalFiltersConfig();
  const { canEditFilters } = usePermissionsProvider();
  const { localEventBus } = useEventsContext();

  const sessionStoragePersistenceActive = useMemo(
    () => getCustomValue('sessionStoragePersistenceForFilters', true),
    [getCustomValue],
  );

  const getInitialAppliedFilters = useCallback((): Filter[] | undefined => {
    const customInitialConditions = getCustomValue<FilterConditionsObject | undefined>(
      'initialFilterConditions',
      undefined,
    );

    if (customInitialConditions) {
      return createFiltersFromConditions(customInitialConditions, allFilterOptions);
    }

    if (initialAppliedFilters) {
      return initialAppliedFilters;
    }

    if (!websiteId || !reportBlockId) return;

    const storedConditions = SessionStorage.getItem(
      StorageKeysManager.getReportFilterConditionsKey({ websiteId: websiteId, reportBlockId: reportBlockId }),
    );

    if (!storedConditions || storedConditions === 'null') return;

    try {
      const parsedConditions = JSON.parse(storedConditions) as FilterConditionsObject;
      if (typeof parsedConditions !== 'object') {
        throw new Error('invalid conditions');
      }

      return createFiltersFromConditions(parsedConditions, allFilterOptions);
    } catch (error) {
      console.error(error);
    }
  }, [allFilterOptions, getCustomValue, initialAppliedFilters, reportBlockId, websiteId]);

  const [appliedFilters, setAppliedFilters] = useState<Filter[]>(getInitialAppliedFilters() ?? []);

  const [isExpanded, setIsExpanded] = useState<boolean>(getCustomValue('initialFiltersExpanded', true));

  const templatesEnabled = useMemo(() => {
    if (!reportBlockId) return false;
    if (!getCustomValue('filterTemplatesAvailable', true)) return false;
    return true;
  }, [getCustomValue, reportBlockId]);

  const createApiConditions = useCallback(() => {
    return buildApiConditions(appliedFilters);
  }, [appliedFilters]);

  useEffect(() => {
    const listener = EventBus.addListener(EVENTS.collapseFilters, () => {
      setIsExpanded(false);
    });

    return () => {
      listener.removeListener();
    };
  }, []);

  const translate = useTranslate();

  const displayFiltersDisabledError = useCallback(() => {
    reportErrorFunc(getCustomValue('disabledFiltersMessage', translate('all.defaultWarnings.cantEditFilters')));
  }, [getCustomValue, reportErrorFunc, translate]);

  const onFilterChangeFunc = useMemo(() => {
    return getCustomValue('onFilterChange', onFilterChange);
  }, [getCustomValue, onFilterChange]);

  const sendFilterChangeEvent = useCallback(
    (updatedFilters: Filter[]) => {
      onFilterChangeFunc?.(updatedFilters);
      // TODO Recheck if this can be replaced with func from customization context
      localEventBus?.dispatch(EVENTS.appliedFiltersChanged, updatedFilters);
      if (websiteId && reportBlockId && sessionStoragePersistenceActive) {
        storeFilters(websiteId, reportBlockId, updatedFilters);
      }
    },
    [localEventBus, onFilterChangeFunc, reportBlockId, sessionStoragePersistenceActive, websiteId],
  );

  const isFilterApplied = useCallback(
    (filterId: string) => {
      return appliedFilters.find((filter) => filter.id === filterId);
    },
    [appliedFilters],
  );

  const shouldHighlightFilter = useCallback(
    (filter: Filter | NestedFiltersPage) => {
      return (
        (isFilter(filter) && !!isFilterApplied(filter.id)) ||
        (isNestedFiltersPage(filter) && filter?.filters.some((filter) => !!isFilterApplied(filter.id)))
      );
    },
    [isFilterApplied],
  );

  const updateAppliedFilter = useCallback(
    (filterId: string, { values, operator }: { values?: any[]; operator?: FilterOperators }) => {
      if (!canEditFilters) {
        displayFiltersDisabledError();
        return;
      }

      setAppliedFilters((prev) => {
        const filterIndex = prev.findIndex((filter) => filter.id === filterId);
        if (filterIndex === -1) return prev;

        const updatedFiltersList = [...prev];

        const updatedFilter = Object.assign({}, updatedFiltersList[filterIndex]);

        if (!isNil(values)) {
          updatedFilter.values = values;
        }

        if (operator) {
          updatedFilter.operator = operator;
        }

        updatedFiltersList[filterIndex] = updatedFilter;

        sendFilterChangeEvent(updatedFiltersList);
        return updatedFiltersList;
      });
    },
    [canEditFilters, displayFiltersDisabledError, sendFilterChangeEvent],
  );

  const applySingleNewFilter = useCallback(
    (newFilter: Filter) => {
      setAppliedFilters((prev) => {
        const newFilters = [...prev, newFilter];
        sendFilterChangeEvent(newFilters);
        return newFilters;
      });
    },
    [sendFilterChangeEvent],
  );

  const applyMultipleFilters = useCallback(
    (newFilters: Filter[], deletePrevious = true) => {
      if (deletePrevious) {
        setAppliedFilters(newFilters);
        sendFilterChangeEvent(newFilters);
      } else {
        setAppliedFilters((prev) => {
          const updatedList = [...prev, ...newFilters];
          sendFilterChangeEvent(updatedList);
          return updatedList;
        });
      }
    },
    [sendFilterChangeEvent],
  );

  const deleteAppliedFilter = useCallback(
    (filterId: string) => {
      if (!canEditFilters) {
        displayFiltersDisabledError();
        return;
      }

      setAppliedFilters((prev) => {
        const updatedList = prev.filter((prevFilter) => {
          if (prevFilter.id !== filterId) return true;

          onFilterRemove?.(prevFilter);
          return false;
        });
        sendFilterChangeEvent(updatedList);
        return updatedList;
      });
    },
    [canEditFilters, displayFiltersDisabledError, onFilterRemove, sendFilterChangeEvent],
  );

  const deleteAllAppliedFilters = useCallback(() => {
    if (!canEditFilters) {
      displayFiltersDisabledError();
      return;
    }

    onFilterRemove?.('all');

    setAppliedFilters([]);
    sendFilterChangeEvent([]);
  }, [canEditFilters, displayFiltersDisabledError, onFilterRemove, sendFilterChangeEvent]);

  const hasAvailableFiltersLeft = useMemo(() => {
    let hasFilters = false;

    const check = (filters: FilterOptions) => {
      filters.forEach((item) => {
        if (isNestedFiltersPage(item)) {
          check(item.filters);
        } else {
          if (!isFilterApplied(item.id)) {
            hasFilters = true;
          }
        }
      });
    };

    check(allFilterOptions);

    return hasFilters;
  }, [allFilterOptions, isFilterApplied]);

  const toggleAppliedFiltersVisibility = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  const expandFilters = useCallback(() => {
    setIsExpanded(true);
  }, []);

  const getAppliedFilterValues = useCallback(
    (removeEmptyValues = true) => {
      return appliedFilters.reduce((acc: Record<string, any>, filter) => {
        const { values, name, multipleValuesAllowed, valueMapper, operator, getApiFieldName } = filter;

        if (removeEmptyValues) {
          if (!values || values.length === 0 || isNil(values[0]) || values[0] === '') {
            return acc;
          }
        }

        let value = multipleValuesAllowed ? values : values[0];
        const fieldName = getApiFieldName ? getApiFieldName(values, operator) : name;

        if (valueMapper) {
          value = valueMapper(value, operator);
        }

        if (Array.isArray(acc[fieldName])) {
          acc[fieldName] = [...acc[fieldName], ...value];
        } else {
          acc[fieldName] = value;
        }

        return acc;
      }, {});
    },
    [appliedFilters],
  );

  const applyConditions = useCallback(
    (conditions: FilterConditionsObject) => {
      const filters = createFiltersFromConditions(conditions, allFilterOptions);
      applyMultipleFilters(filters);
    },
    [allFilterOptions, applyMultipleFilters],
  );

  const appliedFilterValues = useMemo(() => getAppliedFilterValues(), [getAppliedFilterValues]);

  return (
    <FiltersContext.Provider
      value={{
        hasAvailableFiltersLeft,
        reportBlockId: reportBlockId,
        appliedFilters,
        applySingleNewFilter,
        deleteAppliedFilter,
        deleteAllAppliedFilters,
        toggleAppliedFiltersVisibility,
        isExpanded,
        isFilterApplied,
        shouldHighlightFilter,
        appliedFilterValues,
        getAppliedFilterValues,
        updateAppliedFilter,
        allFilterOptions,
        applyMultipleFilters,
        applyConditions,
        templatesEnabled,
        createApiConditions,
        expandFilters,
        isDropdownOpen,
        setIsDropdownOpen,
        preFilterChange,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

function useFiltersContext<T>() {
  return useContext(FiltersContext) as FilterContextType & { appliedFilterValues: T };
}

export { FiltersContextProvider, useFiltersContext };
