import { TEST_IDS } from '@va/constants';
import { ExportIconV2 } from '@va/icons';
import { useTranslate } from '@va/localization';
import {
  Button,
  GrayGradientDivider,
  MenuList,
  MenuListItemType,
  Paragraph,
  fontWeights,
  paragraphSizes,
} from '@va/ui/design-system';
import { useTooltipContext } from '@va/ui/tooltips';
import { useMemo } from 'react';

export type DownloadBtnTooltipContentProps = {
  downloadCSV?: () => void;
  downloadPDF?: () => void;
  downloadPNG?: () => void;
  downloadXLSX?: () => void;
  infoNote?: string;
  title?: string;
};

export const DownloadBtnTooltipContent = ({
  downloadCSV,
  downloadPDF,
  downloadPNG,
  downloadXLSX,
  infoNote,
  title,
}: DownloadBtnTooltipContentProps) => {
  const translate = useTranslate();
  const { context } = useTooltipContext();

  const menuItems = useMemo<MenuListItemType[]>(
    () => [
      ...(downloadCSV
        ? [
            {
              id: 'downloadCSV',
              icon: <ExportIconV2 />,
              label: translate('all.labels.exportCSV'),
              'data-testid': TEST_IDS.generic.export.getExportOptionId('csv'),
              onClick: downloadCSV,
            },
          ]
        : []),
      ...(downloadPDF
        ? [
            {
              id: 'downloadPDF',
              icon: <ExportIconV2 />,
              label: translate('all.labels.exportPDF'),
              'data-testid': TEST_IDS.generic.export.getExportOptionId('pdf'),
              onClick: downloadPDF,
            },
          ]
        : []),
      ...(downloadPNG
        ? [
            {
              id: 'downloadPNG',
              icon: <ExportIconV2 />,
              label: translate('all.labels.exportPNG'),
              'data-testid': TEST_IDS.generic.export.getExportOptionId('png'),
              onClick: downloadPNG,
            },
          ]
        : []),
      ...(downloadXLSX
        ? [
            {
              id: 'downloadXLSX',
              icon: <ExportIconV2 />,
              label: translate('all.labels.exportXLSX'),
              'data-testid': TEST_IDS.generic.export.getExportOptionId('xlsx'),
              onClick: downloadXLSX,
            },
          ]
        : []),
    ],
    [downloadCSV, downloadPDF, downloadPNG, downloadXLSX, translate],
  );

  return (
    <div data-testid={TEST_IDS.generic.export.dropdownContainer} className='rounded-18 p-1.5 bg-white w-360px'>
      {title && (
        <>
          <div className='p-3'>
            <Paragraph colorClassName='text-gray-charcoal' weight={fontWeights.medium}>
              {title}
            </Paragraph>
          </div>
          <GrayGradientDivider />
        </>
      )}
      <MenuList items={menuItems} />
      {infoNote && (
        <Paragraph
          className='py-1 px-2'
          size={paragraphSizes.tiny}
          colorClassName='text-gray-devil'
          weight={fontWeights.medium}
        >
          {infoNote}
        </Paragraph>
      )}
      <Button
        data-testid={TEST_IDS.generic.buttons.close}
        onClick={() => context.onOpenChange(false)}
        color='tertiary'
        className='w-full mt-[3px] py-9px'
        text={translate('button.close')}
        size='small'
        textClasses='text-12 leading-18'
      />
    </div>
  );
};
