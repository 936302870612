import { StopIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { useConfirmationDialogContext } from '@va/shared/util-confirmation';
import { Filter } from '@va/types/filters';
import { Button, HorizontalDivider } from '@va/ui/design-system';
import { usePermissionsProvider } from '@va/util/misc';
import classNames from 'classnames';
import { useMemo } from 'react';
import { AppliedAddFilterButton } from './AppliedAddFilterButton';
import { CombinationalOperator } from './CombinationalOperator';
import { useGlobalFiltersConfig } from './ctx';
import { useFiltersContext } from './filters-context';
import { AppliedFilter, ExternalAppliedFilter } from './general-filter-inputs';
import { RemoveAdditionalFiltersModal } from './RemoveAdditionalFiltersModal';

export const AppliedFilters = ({
  className,
  keepHorizontalDivider = true,
  size = 'large',
  keepRemoveAll = true,
}: {
  className?: string;
  keepHorizontalDivider?: boolean;
  size?: 'small' | 'medium' | 'large';
  keepRemoveAll?: boolean;
}) => {
  const {
    appliedFilters,
    isExpanded,
    deleteAllAppliedFilters,
    hasAvailableFiltersLeft: shouldDisplayAddFilterBtn,
    templatesEnabled,
  } = useFiltersContext();
  const translate = useTranslate();
  const { confirm } = useConfirmationDialogContext();
  const { SaveTemplateButton } = useGlobalFiltersConfig();
  const { canEditFilters } = usePermissionsProvider();

  const horizontalSeparatorLine = useMemo(() => <HorizontalDivider className='-translate-x-5' />, []);
  const isSaveTemplateButtonAvailable = canEditFilters && templatesEnabled && SaveTemplateButton;
  const isRemoveAllButtonAvailable = canEditFilters && (keepRemoveAll || appliedFilters.length > 1);
  const areActionsAvailable = shouldDisplayAddFilterBtn || isSaveTemplateButtonAvailable || isRemoveAllButtonAvailable;
  const filterItems = useMemo(() => {
    const items: React.ReactNode[] = [];

    const { filtersWithoutParent, ...filterGroups } = appliedFilters.reduce(
      (
        acc: {
          filtersWithoutParent: Filter[];
          [key: 'filtersWithoutParent' | string]: Filter[];
        },
        filter,
      ) => {
        if (!filter.parentId || !filter.logicalOperator) {
          acc.filtersWithoutParent.push(filter);
          return acc;
        }

        if (!acc[filter.parentId]) {
          acc[filter.parentId] = [];
        }

        acc[filter.parentId].push(filter);
        return acc;
      },
      { filtersWithoutParent: [] },
    );

    const filterGroupsValues = Object.values(filterGroups).filter((filters) => {
      if (filters.length === 1) {
        filtersWithoutParent.push(filters[0]);
        return false;
      }
      return true;
    });

    filterGroupsValues.forEach((filters, filterGroupIndex) => {
      items.push(
        <div
          key={`group-${filters[0].parentId}`}
          className={classNames('flex flex-wrap items-center bg-gray-mercury-darker xs:w-full overflow-hidden', {
            'rounded-12': size === 'medium',
            'rounded-15': size === 'large',
          })}
        >
          {filters.map((filter, index) => (
            <div className='flex overflow-hidden' key={`filter-${filter.id}`}>
              {index !== 0 && (
                <CombinationalOperator
                  key={`operator-${filter.id}`}
                  size={size}
                  operator={filter.logicalOperator}
                  className='bg-gray-mercury-darker sm:h-42px'
                />
              )}
              <AppliedFilter key={filter.id} filter={filter} size={size} isGrouped />
            </div>
          ))}
        </div>,
      );

      if (filterGroupIndex !== filterGroupsValues.length - 1 || filtersWithoutParent.length) {
        items.push(<CombinationalOperator size={size} key={`operator-${filters[0].parentId}`} />);
      }
    });

    filtersWithoutParent.forEach((filter, index) => {
      if (filter.viewOnly) {
        items.push(<ExternalAppliedFilter key={filter.id} filter={filter} size={size} disableEditing />);
      } else {
        items.push(<AppliedFilter key={filter.id} filter={filter} size={size} />);
      }

      if (index !== filtersWithoutParent.length - 1) {
        items.push(<CombinationalOperator size={size} key={`operator-without-parent-${filter.id}`} />);
      }
    });

    return items;
  }, [appliedFilters, size]);

  if (appliedFilters.length === 0 || !isExpanded) {
    return null;
  }

  return (
    <div className='shrink-0 py-[1px]'>
      {keepHorizontalDivider && horizontalSeparatorLine}
      <div
        className={classNames('flex flex-wrap items-center gap-2 sm:gap-1.5 w-full overflow-hidden', className, {
          'min-h-12': size === 'large',
        })}
      >
        {filterItems.map((item, index) => (
          <div key={index} className='contents'>
            {item}
          </div>
        ))}
        {areActionsAvailable && (
          <div
            className={classNames(
              'flex flex-row bg-gray-concrete p-3px sm:p-[1.5px] gap-[1.5px] xs:w-full overflow-hidden',
              {
                'rounded-15': size === 'large',
                'rounded-12': size === 'medium',
              },
            )}
          >
            {shouldDisplayAddFilterBtn && <AppliedAddFilterButton size={size} />}
            {isSaveTemplateButtonAvailable && <SaveTemplateButton isAddFilterBtnVisible={shouldDisplayAddFilterBtn} />}
            {isRemoveAllButtonAvailable && (
              <Button
                color='quinary'
                text={translate('button.removeAllFilters')}
                icon={() => <StopIcon className='shrink-0' color={'#EA2A0C'} />}
                className={classNames('flex-1 md:min-w-min rounded-l-none', {
                  'h-42px sm:h-9 sm:py-9px sm:px-3 sm:divide-x-[7.5px] sm:gap-0': size === 'large',
                  'h-36px': size === 'medium',
                })}
                textClasses='text-12 text-red-pure sm:leading-18'
                onClick={() =>
                  confirm(() => {
                    deleteAllAppliedFilters();
                  }, RemoveAdditionalFiltersModal)
                }
              />
            )}
          </div>
        )}
      </div>
      {keepHorizontalDivider && horizontalSeparatorLine}
    </div>
  );
};

export default AppliedFilters;
