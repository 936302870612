import { Button } from '@va/ui/design-system';
import classNames from 'classnames';
import { ReactNode } from 'react';

type ReportBlockNodeButtonProps = {
  onClick?: () => void;
  className?: string;
  icon: ReactNode;
};

export const ReportBlockNodeButton = ({ onClick, className, icon }: ReportBlockNodeButtonProps) => {
  return (
    <Button
      className={classNames('sm:w-9 sm:h-9 sm:p-9px sm:rounded-9px', className)}
      color='tertiary'
      onClick={onClick}
      icon={() => icon}
    />
  );
};
