import { TEST_IDS } from '@va/constants';
import classNames from 'classnames';
import { fontWeights, Paragraph } from '../typography';
import { MenuListItem, MenuListItemType } from './MenuListItem';

type MenuListProps = {
  className?: string;
  items: MenuListItemType[];
  noItemsMessage?: string;
};

export const MenuList = ({ className, items, noItemsMessage }: MenuListProps) => {
  return (
    <ul className={classNames('overflow-auto scrollbar scrollbar-thumb rounded-9px', className)}>
      {items.map((item, index, arr) => {
        const prevItem = arr[index - 1];
        const nextItem = arr[index + 1];
        const isPrevSelected = prevItem?.selected;
        const isNextSelected = nextItem?.selected;

        return (
          <MenuListItem
            data-testid={item['data-testid'] ?? TEST_IDS.helpers.createListItemId(item.id)}
            key={item.id}
            onClick={() => {
              item.onClick?.();
            }}
            className={classNames({
              'rounded-t-none': isPrevSelected && item.selected,
              'rounded-b-none': isNextSelected && item.selected,
            })}
            label={item.label}
            icon={item.icon}
            endAdornment={item.endAdornment}
            disabled={item.disabled}
            selected={item.selected}
            tooltip={item.tooltip}
            tooltipProps={item.tooltipProps}
            logicalOperator={item.logicalOperator}
          />
        );
      })}
      {items.length === 0 && noItemsMessage && (
        <Paragraph className='py-3' weight={fontWeights.medium}>
          {noItemsMessage}
        </Paragraph>
      )}
    </ul>
  );
};
