import { getProviderName, getSupportCenterDomain } from '@va/dashboard/selectors/customization';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useDefaultTranslationOptions = () => {
  const providerName = useSelector(getProviderName);
  const supportCenterDomain = useSelector(getSupportCenterDomain);

  return useMemo(
    () => ({
      providerName,
      supportCenterDomain,
    }),
    [providerName, supportCenterDomain],
  );
};
