import { VerticalThreeDotIcon } from '@va/icons';
import { ReportBlockNodeItem } from '@va/types/report-block';
import { TooltipContext } from '@va/ui/tooltips';
import classNames from 'classnames';
import { useState } from 'react';
import { useReportBlockHeaderCtx } from '../report-block-header-ctx';
import { ReportBlockActiveNodes } from './ReportBlockActiveNodes';
import { ReportBlockNodeButton } from './ReportBlockNodeButton';
import { ReportBlockNodesMenu } from './ReportBlockNodesMenu';
import { ReportBlockNodesTooltip } from './ReportBlockNodesTooltip';
import { ReportBlockVisibleNode } from './ReportBlockVisibleNode';

type ReportBlockNodesProps = {
  className?: string;
};

export const ReportBlockNodes = ({ className }: ReportBlockNodesProps) => {
  const { headerNodes } = useReportBlockHeaderCtx();

  if (headerNodes.length === 0) return null;

  return (
    <div className={classNames('flex overflow-hidden gap-4.5px', className)}>
      <ReportBlockActiveNodes />
      <div className='flex gap-4.5px overflow-hidden sm:hidden'>
        {headerNodes.map((node) => (
          <ReportBlockVisibleNode key={node?.id} node={node} />
        ))}
      </div>
      <MoreButton />
    </div>
  );
};

const MoreButton = () => {
  const { headerNodes } = useReportBlockHeaderCtx();
  const [selectedNode, setSelectedNode] = useState<ReportBlockNodeItem | null>(null);

  if (headerNodes.length === 0) return null;

  return (
    <ReportBlockNodesTooltip
      content={
        <ReportBlockNodesMenu nodes={headerNodes} selectedNode={selectedNode} setSelectedNode={setSelectedNode} />
      }
      hideCloseButton={selectedNode?.hideCloseButton}
    >
      <div className='shrink-0 min-w-fit md:hidden'>
        <TooltipContext.Consumer>
          {({ context }) => (
            <ReportBlockNodeButton
              icon={<VerticalThreeDotIcon />}
              onClick={() => setSelectedNode(null)}
              className={classNames({
                '!bg-black-lighter-13': context.open,
              })}
            />
          )}
        </TooltipContext.Consumer>
      </div>
    </ReportBlockNodesTooltip>
  );
};
