import { ArrowRightIcon, EmptyCheckmarkIcon, FilledCheckmarkIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { TestAttributes } from '@va/types/component';
import { TooltipWrapper, TooltipWrapperProps } from '@va/ui/tooltips';
import classNames from 'classnames';
import { forwardRef, ReactNode, useMemo } from 'react';
import { fontWeights, Paragraph, paragraphSizes, ParagraphWithTooltip } from '../typography';
import './menu-list.scss';

export type MenuListItemType = {
  id: string;
  onClick?: () => void;
  className?: string;
  icon?: ReactNode;
  label: string;
  endAdornment?: ReactNode;
  disabled?: boolean;
  selected?: boolean;
  logicalOperator?: string;
  tooltip?: string;
  tooltipProps?: Omit<TooltipWrapperProps, 'content'>;
} & TestAttributes;

type Props = Omit<MenuListItemType, 'id'>;

export const MenuListItem = forwardRef<HTMLLIElement, Props>(
  (
    {
      'data-testid': dataTestId,
      onClick,
      icon,
      label,
      endAdornment: endAdornmentProp,
      className,
      disabled,
      selected,
      logicalOperator,
      tooltip,
      tooltipProps,
    },
    ref,
  ) => {
    const translate = useTranslate();

    const endAdornment = useMemo(() => {
      if (endAdornmentProp) return endAdornmentProp;
      if (selected === undefined) return <ArrowRightIcon className='h-2.5' color='#808080' />;

      return selected ? (
        <FilledCheckmarkIcon className='w-18px h-18px' color='#09AF5C' />
      ) : (
        <EmptyCheckmarkIcon className='w-18px h-18px' />
      );
    }, [endAdornmentProp, selected]);

    return (
      <TooltipWrapper content={tooltip} disabled={!tooltip} {...tooltipProps}>
        <li
          ref={ref}
          data-testid={dataTestId}
          onClick={disabled ? undefined : onClick}
          className={classNames(
            'menu-list-item flex gap-1.5 w-full items-center rounded-9px overflow-hidden',
            {
              'cursor-not-allowed opacity-50': disabled,
              'cursor-pointer hover:bg-white-snow active:bg-white-smoke': !disabled,
              'bg-white-smoke menu-list-item--applied': selected,
            },
            className,
          )}
        >
          {logicalOperator && (
            <div className='menu-list-item__logical-operator hidden bg-gray-mercury-darker items-center w-[34px] self-stretch'>
              <Paragraph size={paragraphSizes.tiny} weight={fontWeights.medium}>
                {translate(`all.filters.combinationalOperators.${logicalOperator}`)}
              </Paragraph>
            </div>
          )}
          <div className='flex flex-1 items-center justify-between gap-3 p-2 w-full'>
            <div className='flex overflow-hidden items-center gap-2'>
              {icon}
              <ParagraphWithTooltip size={paragraphSizes.tiny} weight={fontWeights.medium}>
                {label}
              </ParagraphWithTooltip>
            </div>
            {endAdornment}
          </div>
        </li>
      </TooltipWrapper>
    );
  },
);
