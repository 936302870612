import classNames from 'classnames';
import { memo, useMemo } from 'react';
import { ReportBlockNodes } from './nodes';
import { useReportBlockCtx } from './report-block-ctx';
import { getReportBlockPaginationId } from './report-block.helpers';
import { ReportBlockTitle } from './ReportBlockTitle';

export const ReportBlockHeader = memo(() => {
  const { nodes, id, size = 'medium', headerClassName, disableHeaderWrap = false } = useReportBlockCtx();

  const paginationId = useMemo(() => getReportBlockPaginationId(id), [id]);

  return (
    <div
      className={classNames(headerClassName, {
        'flex items-center': !nodes?.bulkActions,
      })}
    >
      {nodes?.bulkActions && <ReportBlockTitle />}
      <div
        className={classNames('invisible-on-screenshot flex items-center overflow-hidden justify-end w-full', {
          'mt-3 sm:mt-1': nodes?.bulkActions,
          'flex-wrap': !disableHeaderWrap,
          'gap-1.5': size === 'small',
          'gap-4.5px': size === 'medium',
        })}
      >
        {!nodes?.bulkActions && <ReportBlockTitle />}
        {nodes?.bulkActions && <div className='mr-auto'>{nodes?.bulkActions}</div>}
        {nodes?.rightSideJSX}
        {nodes?.datePicker}
        <ReportBlockNodes />
        {nodes?.pagination ? (
          <div className='sm:basis-[100%] flex justify-end empty:hidden'>{nodes.pagination}</div>
        ) : (
          <div className='sm:basis-[100%] flex justify-end empty:hidden min-w-fit' id={paginationId}></div>
        )}
        {nodes?.dataViewButton}
      </div>
    </div>
  );
});

export const ReportBlockSubHeader = ({ className }: { className?: string }) => {
  const { nodes } = useReportBlockCtx();

  return (
    <div className={classNames('mb-18px', className)}>
      {nodes?.segments}
      {nodes?.appliedFilters}
    </div>
  );
};
