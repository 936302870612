import { ReportBlockNodeItem } from '@va/types/report-block';
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useReportBlockCtx } from './report-block-ctx';

export type ReportBlockHeaderCtx = {
  headerNodes: ReportBlockNodeItem[];
  setHeaderNodes: Dispatch<SetStateAction<ReportBlockNodeItem[]>>;
};

const ReportBlockHeaderCtx = createContext<ReportBlockHeaderCtx>({ headerNodes: [], setHeaderNodes: () => {} });

export const ReportBlockHeaderProvider = ({ children }: PropsWithChildren) => {
  const { nodes } = useReportBlockCtx();
  const rightSideNodes = useMemo(
    () => (Array.isArray(nodes?.rightSide) ? nodes.rightSide : [nodes?.rightSide ?? ([] as ReportBlockNodeItem[])]),
    [nodes?.rightSide],
  );

  const [headerNodes, setHeaderNodes] = useState<ReportBlockNodeItem[]>(
    [
      ...rightSideNodes,
      nodes?.downloadButton ?? [],
      nodes?.compareTo ?? [],
      nodes?.segmentsButton ?? [],
      nodes?.filterButton ?? [],
      nodes?.productSelector ?? [],
    ].flat(),
  );

  useEffect(() => {
    setHeaderNodes(
      [
        ...rightSideNodes,
        nodes?.downloadButton ?? [],
        nodes?.compareTo ?? [],
        nodes?.segmentsButton ?? [],
        nodes?.filterButton ?? [],
        nodes?.productSelector ?? [],
      ].flat(),
    );
  }, [nodes, rightSideNodes, setHeaderNodes]);

  return (
    <ReportBlockHeaderCtx.Provider value={{ headerNodes, setHeaderNodes }}>{children}</ReportBlockHeaderCtx.Provider>
  );
};

export const useReportBlockHeaderCtx = () => useContext(ReportBlockHeaderCtx);
