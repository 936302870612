import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  FloatingPortal,
  offset,
  safePolygon,
  shift,
  size,
  useClick,
  useClientPoint,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useMergeRefs,
} from '@floating-ui/react';
import { TEST_IDS } from '@va/constants';
import { TooltipOptions } from '@va/types/tooltip';
import { appHasDarkTheme } from '@va/util/helpers';
import classNames from 'classnames';
import React, { createContext, forwardRef, PropsWithChildren, useCallback, useMemo, useRef, useState } from 'react';

type TooltipContext = ReturnType<typeof useTooltip>;

export const TooltipContext = createContext<TooltipContext>({} as TooltipContext);
const VIEWPORT_VERTICAL_PADDING = 20;

export const Tooltip: React.FC<PropsWithChildren<TooltipOptions>> = ({ children, ...rest }) => {
  const context = useTooltip(rest);

  return <TooltipContext.Provider value={context}>{children}</TooltipContext.Provider>;
};

export const TooltipTrigger = forwardRef<HTMLDivElement, React.PropsWithChildren<{}>>(({ children }, ref) => {
  const { refs, getReferenceProps } = useTooltipContext();

  const mergedREf = useMergeRefs([refs.setReference, ref]);

  if (React.isValidElement(children)) {
    return React.cloneElement(children, getReferenceProps({ ...children.props, ref: mergedREf }));
  }

  return (
    <div ref={mergedREf} {...getReferenceProps()}>
      {children}
    </div>
  );
});

export const TooltipContent: React.FC<
  PropsWithChildren<
    Pick<
      TooltipOptions,
      | 'appendTo'
      | 'arrow'
      | 'arrowColor'
      | 'zIndex'
      | 'disabled'
      | 'tooltipClassNames'
      | 'useDefaultStyle'
      | 'useParentWidth'
      | 'maxWidth'
      | 'unmountOnClose'
    >
  >
> = ({
  children,
  appendTo,
  arrow = true,
  arrowColor,
  zIndex = 9999,
  tooltipClassNames,
  useDefaultStyle = true,
  useParentWidth = false,
  maxWidth = 'xs:max-w-[290px] max-w-[600px]',
  unmountOnClose = true,
}) => {
  const { getFloatingProps, refs, floatingStyles, context, arrowRef } = useTooltipContext();
  const isDarkTheme = appHasDarkTheme();

  const arrowBgColor = useMemo(() => {
    if (arrowColor) return arrowColor;
    return isDarkTheme ? '#FFFFFF' : '#000000';
  }, [arrowColor, isDarkTheme]);

  const tooltipStyles = useMemo(() => {
    if (!useDefaultStyle) return tooltipClassNames;

    return classNames(
      'p-2 rounded-md z-[100] text-12 sm:mx-2 flex xs:!max-h-[290px]',
      maxWidth,
      {
        'bg-black-lighter-84 text-white': !isDarkTheme,
        'bg-white-84 text-black border-solid border-gray border-2': isDarkTheme,
      },
      tooltipClassNames,
    );
  }, [useDefaultStyle, isDarkTheme, tooltipClassNames, maxWidth]);

  const getRoot = useCallback(() => {
    if (appendTo === 'parent') {
      return refs.domReference.current?.parentNode as HTMLElement;
    }

    if (appendTo) {
      return appendTo;
    }

    return document.querySelector('#root') as HTMLElement;
  }, [appendTo, refs.domReference]);

  if (unmountOnClose && !context.open) {
    return null;
  }

  return (
    <FloatingPortal root={getRoot()}>
      <div
        data-testid={TEST_IDS.generic.tooltipContent}
        style={{
          ...floatingStyles,
          zIndex,
          ...(useParentWidth ? { width: `${refs.domReference.current?.clientWidth}px` } : {}),
        }}
        className={classNames('break-words', tooltipStyles, {
          hidden: unmountOnClose === false && !context.open,
        })}
        ref={refs.setFloating}
        {...getFloatingProps()}
      >
        <div className='overflow-auto scrollbar scrollbar-thumb w-full'>{children}</div>
        {arrow && <FloatingArrow fill={arrowBgColor} context={context} ref={arrowRef} alignmentBaseline='auto' />}
      </div>
    </FloatingPortal>
  );
};

export const useTooltip = ({
  initialOpen = false,
  open: controlledIsOpen,
  onOpenChange,
  placement,
  trigger = 'default',
  interactive,
  disabled,
  anchor,
  followCursorAxis,
  outsidePress,
  offset: offsetVal = 10,
  shiftCrossAxis = false,
}: TooltipOptions) => {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);

  const isOpen = controlledIsOpen ?? uncontrolledOpen;

  const arrowRef = useRef(null);

  const data = useFloating({
    open: isOpen,
    onOpenChange: onOpenChange ?? setUncontrolledOpen,
    placement: placement === 'auto' ? undefined : placement,
    // See documentation for details about each middleware option
    middleware: [
      offset(offsetVal),
      flip(),
      shift({ crossAxis: shiftCrossAxis }),
      size({
        apply({ availableHeight, elements }) {
          elements.floating.style.maxHeight = `${availableHeight - VIEWPORT_VERTICAL_PADDING}px`;
        },
      }),
      arrow({ element: arrowRef, padding: 20 }),
    ],
    whileElementsMounted: autoUpdate,
    elements: {
      reference: anchor,
    },
  });

  const clientPoint = useClientPoint(data.context, {
    enabled: !!followCursorAxis,
    axis: followCursorAxis,
  });

  const click = useClick(data.context, {
    enabled: ['click'].includes(trigger) && !disabled,
    keyboardHandlers: false,
  });
  const hover = useHover(data.context, {
    handleClose: interactive ? safePolygon() : undefined,
    enabled: ['default', 'hover'].includes(trigger) && !disabled,
  });

  const dismiss = useDismiss(data.context, { ancestorScroll: true, outsidePress: outsidePress });

  const interactions = useInteractions([dismiss, hover, click, clientPoint]);

  return {
    ...data,
    ...interactions,
    arrowRef,
  };
};

export const useTooltipContext = () => {
  const context = React.useContext(TooltipContext);

  if (context == null) {
    throw new Error('Tooltip components must be wrapped in <Tooltip />');
  }

  return context;
};
