import { useReportBlockHeaderCtx } from '../report-block-header-ctx';
import { ReportBlockActiveNode } from './ReportBlockActiveNode';

export const ReportBlockActiveNodes = () => {
  const { headerNodes } = useReportBlockHeaderCtx();

  return (
    <div className='shrink-0 empty:hidden flex gap-4.5px'>
      {headerNodes.map((node) => {
        return <ReportBlockActiveNode key={node.id} node={node} />;
      })}
    </div>
  );
};
