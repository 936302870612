import { SegmentIconV2 } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Button } from '@va/ui/design-system';
import { DropdownArrow } from '@va/util/components';
import { useWindowDimensions } from '@va/util/hooks';
import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { AddSegmentsTooltip } from './AddSegmentsTooltip';
import { useSegmentsContext } from './SegmentsContext';

type AddSegmentsButtonProps = {
  size?: 'small' | 'large';
};

export function AddSegmentsButton({ size = 'large' }: AddSegmentsButtonProps) {
  const translate = useTranslate();
  const { isMediumDevice } = useWindowDimensions();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const { selectedSegments, isExpanded, setIsExpanded } = useSegmentsContext();

  const iconsColor = useMemo(
    () => (isExpanded || selectedSegments.length ? '#FFFFFF' : '#3C3C3C'),
    [isExpanded, selectedSegments.length],
  );

  return (
    <AddSegmentsTooltip isOpen={isTooltipOpen} openChange={setIsTooltipOpen}>
      <div>
        <Button
          className={classNames('h-12 sm:w-9 sm:h-9 sm:rounded-9px sm:p-9px', {
            '!bg-green-haze': !(isExpanded || isTooltipOpen) && selectedSegments.length,
            '!bg-black-lighter-13': !selectedSegments.length && isTooltipOpen,
          })}
          color={isExpanded || isTooltipOpen ? 'primary' : 'tertiary'}
          icon={() => <SegmentIconV2 className='sm:w-15px sm:h-15px' color={iconsColor} />}
          bottomIconWrapperCss='sm:bottom-0.5'
          bottomIcon={
            <DropdownArrow
              iconClassName={classNames({
                'w-1.5': size === 'small',
                'w-2.5 sm:w-1.5': size === 'large',
              })}
              open={isExpanded}
              color={isExpanded || selectedSegments.length ? '#FFFFFF80' : iconsColor}
            />
          }
          onClick={() => {
            if (!selectedSegments.length) {
              setIsTooltipOpen((prev) => !prev);
            } else {
              setIsExpanded(!isExpanded);
            }
          }}
          tooltip={translate('tooltip.label.applySegments')}
          tooltipProps={{ disabled: isMediumDevice }}
        />
      </div>
    </AddSegmentsTooltip>
  );
}
