import { CogIconV2, SegmentIconV2 } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Button, MenuList, MenuListItemType } from '@va/ui/design-system';
import { useMemo } from 'react';
import { useHistory } from 'react-router';
import { useSegmentsContext } from './SegmentsContext';

export function AppliedSegmentsList() {
  const history = useHistory();
  const { segments, isSegmentSelected, toggleSegmentSelection } = useSegmentsContext();
  const translate = useTranslate();

  const menuItems = useMemo<MenuListItemType[]>(
    () =>
      segments?.map((segment) => ({
        id: segment.id,
        label: segment.name,
        icon: <SegmentIconV2 />,
        selected: isSegmentSelected(segment.id),
        onClick: () => toggleSegmentSelection(segment.id),
      })),
    [isSegmentSelected, segments, toggleSegmentSelection],
  );

  return (
    <div className='flex flex-col overflow-hidden'>
      <MenuList className='max-h-72' items={menuItems} />
      <Button
        text={translate('all.segments.editAndManage')}
        icon={(_, color) => <CogIconV2 color={color} />}
        onClick={() => {
          history.push('/settings/visitor-segments');
        }}
        color='tertiary'
        className='w-full mt-[3px] shrink-0 justify-center py-9px'
        textClasses='text-12 leading-18'
        size='small'
      />
    </div>
  );
}
